<template>
  <div class="application form">
    <div class="application__title">
      <h2>
        Family Form
      </h2>
    </div>

    <b-spinner label="Loading" v-if="isLoading"></b-spinner>
    
    <div
      v-else
    >

      <p
        v-if="primaryParentInfoFormFields.length"
        class="family-label"
      >
        <strong>Primary Parent Info</strong>
      </p>

      <ApplicationAddRow
        v-for="field in primaryParentInfoFormFields"
        :ref="field.ref"
        v-show="!field.hidden"
        :key="field.id"
        :fields-data="field"
        :field-value="field.value"
        :valid-tracker="validTracker"
        :check-value="false"
        :is-disabled-editing="false"
        @checkConditions = "checkConditions"
      />

      <div
        v-if="familySettings.allow_to_create_multiple_parents && !secondaryParentInfoFormFields.length && primaryParentInfoFormFields.length"
        class="form__row form__one-row"
      >
        <button
          class="btn btn--color-outline-dark form__button"
          @click="addSecondParent"
        >
          <span>+ Add Second Parent</span>
        </button>
      </div>

      <p
        v-if="secondaryParentInfoFormFields.length"
        class="family-label"><strong>Secondary Parent Info</strong>

        <span class="secondary-parent-close" @click="removeSecondParent"></span>
      </p>

      <ApplicationAddRow
        v-for="field in secondaryParentInfoFormFields"
        :ref="field.ref"
        v-show="!field.hidden"
        :key="field.id"
        :fields-data="field"
        :field-value="field.value"
        :valid-tracker="validTracker"
        :check-value="false"
        :is-disabled-editing="false"
        @checkConditions = "checkConditions"
      />


      <p
        v-if="familyInfoFormFields.length"
        class="family-label"
      >
        <strong>Additional Family Info</strong>
      </p>

      <ApplicationAddRow
        v-for="field in familyInfoFormFields"
        :ref="field.ref"
        v-show="!field.hidden"
        :key="field.id"
        :fields-data="field"
        :field-value="field.value"
        :valid-tracker="validTracker"
        :check-value="false"
        :is-disabled-editing="false"
        @checkConditions = "checkConditions"
      />

      <p class="form__error">{{errorMessage}}</p>

      <div
        v-if="validationErrors.length"
        class="form-group"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center form__error"
        >
          {{ validationError }}
        </p>
      </div>

      <div
        v-if="showButtons"
        class="form__row form__one-row"
      >
        <button
          :disabled="isSendButtonDisabled"
          class="btn btn--color-dark form__button"
          @click="sendForm"
        >
          <span>{{ sendButtonText }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BSpinner } from 'bootstrap-vue'
  import ApplicationAddRow from '@/views/Application/ApplicationAddRow.vue'
  import { checkConditionValues } from '@core/mixins/checkConditionValues'
  import { mapGetters } from 'vuex'
  import store from '@/store'
  import useApplicationAdd from '@/views/Application/useApplicationAdd'
  import { getUserData } from '@/auth/utils'
  import {
    hasValue,
    designateValue,
  } from '@/helpers/formConditions'

  export default {
    components: {
      BSpinner,

      ApplicationAddRow,
    },
    mixins: [checkConditionValues],
    data() {
      return {
        familyId: getUserData().family_id,
        userId: getUserData().id,
        programId: this.$router.currentRoute.params.id,
        status: 'TEst',
        validTracker: false,
        errorMessage: '',
        validationErrors: [],
        isLoading: false,
        isSendButtonDisabled: false,
      }
    },
    setup() {
      const {
        saveProgress,
      } = useApplicationAdd()

      return {
        saveProgress,
      }
    },
    computed: {
      ...mapGetters({
        applicationId: 'applicationRegistration/getApplicationId',
        familySettings: 'applicationRegistration/getFamilySettings',
        familyFormFields: 'applicationRegistration/getFamilyFormFields',
        familyFormConditions: 'applicationRegistration/getFamilyFormConditions',
        familyData: 'applicationRegistration/getFamilyData',
      }),
      isFilledFamilyForm: {
        get: function () {
          return store.getters['applicationRegistration/getIsFilledFamilyForm']
        },
        set: function (val) {
          store.commit('applicationRegistration/SET_IS_FILLED_FAMILY_FORM', val)
        }
      },
      showButtons() {
        return true
      },
      sendButtonText() {
        return 'Save'
      },
      primaryParentInfoFormFields() {
        return this.familyFormFields.filter(field => field.section_type === 'parent')
      },
      secondaryParentInfoFormFields() {
        return this.familyFormFields.filter(field => field.section_type === 'secondary_parent')
      },
      familyInfoFormFields() {
        return this.familyFormFields.filter(field => field.section_type === 'family')
      },
    },
    methods: {
      findCanvasRefForField(field) {
        return this.$refs[field.ref][0]?.$refs[`signature_${field.ref}`]?.$refs[field.ref]
      },
      checkConditions() {
        this.checkConditionInFormType(this.familyFormFields)
      },
      async checkConditionInFormType(allFields)
      {
        this.familyFormConditions.forEach(condition => {
          let isMatched
          for (let i = 0; i < condition.scoped_conditions.length; i++) {
            const scopeItems = condition.scoped_conditions[i];
            const scopeItemsResults = scopeItems.map(item => {
              const foundedField = allFields.find(field => field.field_id === item.field_id)
              const result = hasValue(item, foundedField.value)
              return result
            })

            isMatched = scopeItemsResults.every(i => i === true)

            if (isMatched || i == condition.scoped_conditions.length - 1) {

              if (condition.related_type === 'Field') {
                const field = allFields.find(field => field.field_id === condition.related_id)

                if (field) {
                  field.hidden = condition.action === 'Show' ? !isMatched : isMatched
                  if (!isMatched) {
                    field.value = designateValue(field.type, field.value, field.options)
                  }
                }
              }
              break
            }
          }
        })
      },
      async addSecondParent() {
        store.commit('applicationRegistration/ADD_SECONDARY_PARENT')
      },
      async removeSecondParent() {
        store.commit('applicationRegistration/REMOVE_SECONDARY_PARENT')
      },
      async validStep() {
        if (this.familyFormFields.filter(field => field.required && !['plain_text', 'signature'].includes(field.type) && (field.value == null || field.value.length == 0) && !field.hidden).length > 0 ) {
          this.errorMessage = 'Please, fill required fields'
          this.validTracker = true
          return
        }

        if (this.familySettings.is_required_second_parent && !this.secondaryParentInfoFormFields.length) {
          this.errorMessage = 'Secondary parent is required. Please add second parent info'
          this.validTracker = true
          return
        }


        this.validTracker = false

        this.errorMessage = ''
      },
      sendForm() {
        this.validTracker = false

        this.validStep()

        if (this.validTracker) return

        this.isSendButtonDisabled = true

        store.commit('SET_LOADING_STATUS', true)

        let queryParams = {
          user_id: this.userId,
          program_id: this.$router.currentRoute.params.id,
          family_id: this.familyId,
          primary_parent_data: {
            form_data: {},
            field_results: [],
          },
          secondary_parent_data: {
            form_data: {},
            field_results: [],
          },
          additional_family_data: {
            form_data: {},
            field_results: [],
          },
          form_id: this.familySettings.family_form_id,
        }

        this.primaryParentInfoFormFields.filter(row => !row.hidden)
          .forEach(row => {
            let result = row.value

            if (row.type === 'signature') {
              const canvasRef = this.findCanvasRefForField(row)
              if (canvasRef && !canvasRef.isEmpty()) {
                result = canvasRef.save()
              }
            }
            queryParams.primary_parent_data.field_results.push({
              field_id: row.field_id,
              result: result,
              field_name: row.name,
            })

            queryParams.primary_parent_data.form_data[row.name] = row.value
          })

        this.secondaryParentInfoFormFields.filter(row => !row.hidden)
          .forEach(row => {
            let result = row.value

            if (row.type === 'signature') {
              const canvasRef = this.findCanvasRefForField(row)
              if (canvasRef && !canvasRef.isEmpty()) {
                result = canvasRef.save()
              }
            }
            queryParams.secondary_parent_data.field_results.push({
              field_id: row.field_id,
              result: result,
              field_name: row.name,
            })
            queryParams.secondary_parent_data.form_data[row.name] = row.value
          })

        this.familyInfoFormFields.filter(row => !row.hidden)
          .forEach(row => {
            let result = row.value

            if (row.type === 'signature') {
              const canvasRef = this.findCanvasRefForField(row)
              if (canvasRef && !canvasRef.isEmpty()) {
                result = canvasRef.save()
              }
            }
            queryParams.additional_family_data.field_results.push({
              field_id: row.field_id,
              result: result,
              field_name: row.name,
            })
            queryParams.additional_family_data.form_data[row.name] = row.value
          })

        store.dispatch('applicationRegistration/saveFamilyForm', queryParams)
          .then(response => {
            if (response.status > 400) {

              if (response.status === 422) {
                const validationErrors = Object.values(response.data.errors)
                this.validationErrors = validationErrors.flat()
              } else {
                this.errorMessage = response.data.message
              }

              this.isSendButtonDisabled = false

              store.commit('SET_LOADING_STATUS', false)
            } else {
              this.isSendButtonDisabled = false

              store.commit('SET_LOADING_STATUS', false)
              this.isFilledFamilyForm = true
              this.$emit('nextStep')
            }
          })
      },
    }
  }
</script>

<style lang="scss">
@import 'src/assets/scss/colors/colors';

.family-label {
  text-align: center;
  font-size: 20px;
  margin: 30px 0;

  .secondary-parent-close {
    position: absolute;
    margin-left: 20px;
    cursor: pointer;
    width: 22px;
    height: 22px;
    background: $primary;
    border-radius: 50%;
    transition: 0.3s;
    align-self: start;
    &:before, &:after {
      position: absolute;
      content: ' ';
      top: 5px;
      left: 10px;
      height: 12px;
      width: 2px;
      background-color: #fff;
    }
    &:before {
      transform: translateX(50%);
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.step-info {
  display: block;
  position: relative;
  margin-left: 10px;
  background: $primary;
  text-align: center;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;

  &__tooltip {
    position: absolute;
    top: 30px;
    background: #F6E9EC;
    border-radius: 15px;
    width: 300px;
    padding: 13px 19px;
    color: $primary;
    top: -10px !important;
  }
}
.continue-btn {
  margin: 0;
  height: 44px;
}
</style>